import i18n from 'i18next';
import { initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'sv-SE',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            guestcode: 'Fill in the code on the invite (lower-right corner in bold text, use an "a" in place of the "ä") to access the RSVP form. Remember to fill in one form per name on the invite envelope!',
            logout: 'Log out',
            login: 'Go!',
            wrongCode: 'Guest not found. Please try again.',
            home: {
                text: 'On Friday the 26th of July and Saturday the 27th of July 2024 we are inviting you to two days of wedding celebrations in the archipelago!',
                text2: 'This page will be updated with more information as the wedding approaches, so check in regularly for the latest excitement!',
                text3: 'XO, Felicia & Ludvig',
                text4: '',
                header: 'FELICIA & LUDVIG',
                welcome: 'WE\'RE GETTING MARRIED!',
                countdown: 'Skärva Herrgård 07/26/2024',
                subtitle: 'July / 26 & 27 / 2024',
            },
            blog: {
              header: "LATEST NEWS",
              rsvp: "RSVP is now live! Head to the RSVP page through the meny or the button below to access the form. Remember to fill out one form per person on the invite envelope!",
              bra: "BRA Flyg currently has a campaign on airfare tickets from 599 SEK if you book before May 5th! Perfect if you want to fly Stockholm Bromma -> Ronneby (Karlskrona). For those with a Stammis membership at ICA (primarily Swedes), they usually have a few discounted tickets as well. Follow the links below to the booking website!",
              bra_standard: "Campaign / Standard booking"
            },
            construction: {
              header: "Under construction...",
              subtitle: "",
              ingress: "More information will be available here closer to the wedding. Come back in a bit!"
          },
            schedule: {
                ingress:"",
                header: "OUR WEDDING WEEKEND",
                subtitle: "A whole week of festivities",
                time: "Time",
                activity: "Activity",
                where: "Where",
                rehearsal: "Rehearsal",
                checkin: "Check-in for those staying at Skärva",
                lunch: "Lunch",
                decoration: "Decorate the reception venue",
                bbq: "Social barbeque with our own troubadour!",
                church: "Fredrik Church",
                kitchen: "kitchen",
                partyvenue: "reception hall",
                partytent: "party tent",
                wednesday: "Wednesday - Archipelago Day",
                thursday: "Thursday - Drinks at Strandis",
                friday: "Friday",
                saturday: "Saturday",
                sunday: "Sunday",
                wed_desc: "Join us for a day out in the Archipelago!",
                thurs_desc: "Join us at our house for some drinks and good company!",
                fri_desc: "15:00 - Fredrik Church",
                sat_desc: "Put on your best outfit again and celebrate our nuptials in the best way we can think of!",
                sun_desc: "Slow start in the morning? No problems!",
                fri_date: 'July 26th',
                sat_date: 'July 27th',
                sun_date: 'July 28th',
                fri_act1: 'Wedding Ceremony',
                fri_act1_location: 'Main Square, Karlskrona',
                fri_act1_time: '3 P.M',
                fri_act2: 'Wedding Toast & Reception',
                fri_act2_location: 'Banquet Hall at First Hotel Statt',
                fri_act2_time: '5:30 P.M - 01 A.M',
                sat_act: 'WEDDING BALL',
                sat_act_time: '1 P.M',
                sat_act_location: 'Skärva Herrgård',
                sat_act_location2: 'Skärva 2, 371 91 Karlskrona',
                sun_act: 'Summer Brunch',
                sun_act_time: '11 A.M',
                sun_act_location: 'Skärva Herrgård'
            },
            vision: {
              vision: "A Celebration of Love",
              text1: "Our wedding vision is",
              text2: "where we will have time to spend with all of our guests, enjoy our wedding, and celebrate accordingly! With that in mind, we have decided to bypass tradition and split our wedding day in two wonderful days!",
              text3: "We want both our guests and ourselves to enjoy the celebration of our love and Karlskrona's wonderful environment in a calm and relaxing way.",
              text4: "As such, we have planned our wedding weekend in a way we believe everyone will appreciate, with plenty of time for both celebrations, relaxing time on your own, and the Swedish summer.",
              cap1: "Archipelago Wedding Deluxe",
              cap2: "Love Celebration in Sweden at its best"
            },
            rsvp: {
                header: "RSVP",
                firstname: "First name",
                lastname: "Family name",
                text: "Here you can give your reply to the wedding invitation. If you need to submit more than one (e.g., for a spouse), please log in using your own personal codes and submit two forms.",
                edit: "Here you can edit your details and RSVP. If you need to submit more than one (e.g., for a spouse), please log in using your own personal codes and submit two forms.",
                reply: "Your status: ",
                positive: "I WILL ATTEND",
                negative: "I WILL NOT ATTEND",
                save: "Save",
                who_english: "Describe your relationship to the bride and/or groom and something interesting about yourself",
                who_swedish: "Leave this blank",
                allergies: "Allergies / Other food preferences",
                all_weekend: "Yes! I wish to attend the entire weekend!",
                only_friday: "I will only attend Friday",
                only_saturday: "I will only attend Saturday",
                attend_label: "I wish to attend...",
                preferences_label: "Food preferences",
                alc_pref_label: "Alcohol Preferences",
                alcohol: "Alcohol",
                non_alcohol: "Non-alcohol",
                acc_label: "Accommodation",
                acc_all_weekend: "I'd like accommodation throughout the weekend",
                acc_fri_sun: "I'd like accommodation only Friday through Sunday",
                acc_people: "Number of people in the room",
                acc_own: "I am from Karlskrona and will not need help with accommodation",
                phone: "Cell number",
                return: "The RSVP form is not yet done, please return on or after the 25th of March :)  "
            },
            accommodation: {
                header: "Accommodation",
                intro: "All accommodations have been arranged by the Bride and Groom and is at cost at various price points negotiated with the venues. Our plan for the weekend assumes all traveling guests stay at these venues, and therefore we encourage everyone to take advantage of our negotiated rates.",
                statt_info: "First Hotel Statt, a turn-of-the-century hotel with breakfast buffet included in the middle of Karlskrona, next to the church and where we will be during Friday evening. Most of our guests will stay here.",
                skarva_info: "Skärva Herrgård, in the middle of a nature reserve by the sea only ten minutes by car from Karlskrona. Skärva only has a few rooms, and will primarily be reserved for the Bride and Groom and the wedding party.",
                general_info1: "You'll select accommodation in the RSVP. We will then to the best of our ability place everyone in rooms based on preferences on location and price point.",
                general_info2: "We need to send in the full list to the hotel",
                general_info3: "at the latest 45 days ahead of the wedding.",
                statt_more: "More information on First Hotel Statt ",
                skarva_more: "More information on Skärva Herrgård ",
                here: "here",
                room: "Room at"
            },
            nav: {
                information: 'INFORMATION',
                schedule: 'OUR WEDDING WEEKEND',
                rsvp: 'RSVP',
                signed: 'Welcome',
                accommodation: 'ACCOMMODATION',
                guestlist: "Guests",
                header: 'Menu',
                vision: 'OUR WEDDING VISION'
            },
            guestlist: {
                header: "Guest List",
                name: "Name",
                  who: "Who?",
                  table: "Table",
                  room: "Room"
            },
            info: {
              header: "Information",
              intro: "In this section we hope to answer most of your questions. If there is anything else you are wondering, please don't hesitate to contact us via email at",
              swe_code: "+46",
              karlskrona: "How do I get to Karlskrona?",
              karlskrona_answer: "For international guests, the easiest way is to fly to Copenhagen Airport and take the train from there towards Karlskrona. The train station is just after customs and past all the check in desks inside the terminal. The train takes about 3 hours and no change is necessary! We also suggest spending some time in Copenhagen, and the train to Karlskrona is just as easy to take from Copenhagen Central as it is from the airport - it is the same line. If you choose to fly to Stockholm (an even better city in the groom's not-so-humble opinion), the easiest way to Karlskrona is to fly to Ronneby Airport with SAS from the Arlanda International Airport or Bromma Regional airport.",
              plus_one: "Can I bring a +1?",
              plus_one_answer: "Unfortunately, we need to stay restrictive on the number of guests due to a lack of space, and as a result all invitations are personal.",
              children: "Can I bring young children?",
              children_answer: "We have discussed this with friends and family who are parents to young ones, and have decided we wish to keep our wedding adult-only. Breast-feeding children are of course welcome and there will be a quiet area on both Friday and Saturday for babysitters and parents with the absolute youngest ones.",
              children_answer2: "However, it is more important to us to have all our friends at our wedding than to be strict on the wedding being \"child free\". We won't adapt the festivities to children, but if the situation requires feel free to bring them! The hotel has cribs which they can put into the rooms.",
              children_answer3: "Some of our guests have little ones who cannot be left with a baby sitter and others bring a grandparent to watch the kids during the festivities, and we'll have a space for baby sitters for both the Friday and Saturday parties. The hotel can also put extra beds in the rooms, especially in the Superior and Deluxe rooms which are slightly larger than Standard.",
              spex: "I would like to hold a speech, how do I sign up?",
              spex_answer: "To our wonderful Toast Masters via email to",
              spex_email: "toast.felicialudvig@gmail.com",
              support: "Is there anything you'd like help with for the wedding?",
              support_answer: "Please! There are tons of things we'd like assistance with; everything from prepping the venues, transportation, etc. Be in touch! :)",
              accommodation: "What about the accommodations?",
              accommodation_answer: "We've made reservations for all guests divided between Karlskrona Statt Hotell and Skärva Herrgård. The accommodations will be at cost and we need to send in the full list to the hotel no later than 45 days before the wedding. More information on the Accommodations page!",
              dresscode: "What is the dress code?",
              dresscode_answer: "More information on this topic to come once the groom has decided what he wants to wear!",
              dresscode_ladies: "Ladies",
              dresscode_gents: "Gentlemen",
              dresscode_friday: "Friday",
              dresscode_saturday: "Saturday",
              dresscode_ladies1: "Colorful, elegant dress compatible with formal / dark suit",
              dresscode_ladies2: "Ball- or evening dress",
              dresscode_gents1: "Formal / dark suit or business- / smart casual",
              dresscode_gents2: "White tie / tailcoat or tuxedo",
              wedding_gift: "Wedding gift / registry?",
              wedding_gift_answer: "None! That you all make it to Karlskrona to celebrate with us is more than enough!",
              parking: "Parking?",
              parking_answer: "There is a parking lot right next to the church for the Friday activities. However, there is a risk it needs to be evacuated at Midnight between Friday and Saturday due to preparations for the annual city music festival. For Saturday, we kindly ask our guests to drop off any passengers up by the venue and then park by the barns just before.",
              what_do: "What is there to do in Karlskrona?",
              what_do_answer1: "Tons! There are sightseeing boats available to see the town from its best side, several golf courses nearby, and lots of good restaurants. Museum-wise, there is for example the Maritime Museum and Blekinge Museum for the more cultured guests. The local pub Fox & Anchor hosts an excellent karaoke on Thursdays, for everyone else.",
              what_do_answer2: "For those who would like, we also strongly recommend staying in Karlskona after the wedding for ",
              what_do_answer21: " which is the week directly following!",
              what_do_answer22: "It's a week full with activities and great concerts with some of Sweden's biggest stars. Don't miss it!",
              restaurants_dinner: "Our favorite restaurants for dinners are:",
              restaurants_lunch: "And for lunch:",
              blomlofs: "Blomlöfs Fish Smokery",
              michels: "For the late night cravings, Michel's is the obvious choice - tried, tested, and approved by the groom himself."
            },
            days_one: '{{count}} day',
            days_other: '{{count}} days',
            hours_one: '{{count}} hour',
            hours_other: '{{count}} hours',
            minutes_one: '{{count}} minute',
            minutes_other: '{{count}} minutes',
            seconds_one: '{{count}} second',
            seconds_other: '{{count}} seconds',
            celebrate: 'We have been married for:'
          // here we will place our translations...
        }
      },
    'sv-SE': {
          translation: {
              logout: 'Logga ut',
              guestcode: 'Fyll i koden som står på inbjudan för att komma till formuläret. Kom ihåg att fylla i ett formulär per person som står på kuvertet!',
              login: 'Kör!',
              wrongCode: 'Felaktig kod. Försök igen.',
              home : {
                text: 'Fredag 26:e juli och lördag 27:e juli 2024 välkomnar vi er till vårt tvådagarsbröllop i Karlskronas stad och skärgård!',
                text2: 'Vår hemsida är vår kommunikationsform och kommer att uppdateras löpande, kika in här ofta för senaste peppen!',
                text3: 'Kram, Felicia & Ludvig',
                text4: '',
                header: 'FELICIA & LUDVIG',
                welcome: 'VI SKA GIFTA OSS!',
                subtitle: '26 & 27 / Juli / 2024',
                countdown: 'Skärva Herrgård 26-28/07/2024'
              },
              blog: {
                header: "SENASTE NYTT",
                rsvp: "Vårt OSA-formulär är nu aktivt! Klicka på OSA i menyn ovan eller knappen nedan för att komma till OSA-sidan. Kom ihåg att fylla i ett formulär per person som står på inbjudningskuvertet!",
                bra: "För er som är ICA Stammis finns möjlighet att boka billiga flygresor till Ronneby/Karlskrona. Följ länken nedan för att komma till bokningen!",
                bra_standard: "Kampanj / Standardbokning"
              },
            construction: {
                header: "Under uppbyggnad...",
                subtitle: '',
                ingress: "Denna sida kommer innehålla mer info närmare bröllopet. Återkom senare!"
            },
            schedule: {
                ingress:"",
                header: "VÅR BRÖLLOPSHELG",
                subtitle: 'En hel vecka av fest!',
                time: "Tid",
                where: "Var",
                activity: "Aktivitet",
                rehearsal: "Genrep",
                checkin: "Check-in för de som bor på Skärva Herrgård",
                lunch: "Lunch",
                decoration: "Dekorering av festlokal",
                bbq: "Grillkväll med vår egna trubadur!",
                church: "Fredrikskyrkan",
                kitchen: "köket",
                partyvenue: "festlokalen",
                partytent: "partytältet",
                wednesday: "Onsdag - Aspödagen",
                thursday: "Torsdag - Drinkar på Strandis",
                friday: "Fredag",
                saturday: "Lördag",
                sunday: "Söndag",
                wed_desc: "Häng med oss ut på en dag ute i Skärgården!",
                thurs_desc: "Kom till vårt hus på lite drinkar och mingel!",
                fri_desc: "15:00 - Fredrikskyrkan",
                sat_desc: "Ta på dig finklänningen och fira vårt bröllop på bästa sätt!",
                sun_desc: "Segstartad? Inga bekymmer!",
                fri_date: '26:e juli',
                sat_date: '27:e juli',
                sun_date: '28:e juli',
                fri_act1: 'Vigsel',
                fri_act1_time: '15:00',
                fri_act2: 'Brudskål & Vigselfest',
                fri_act1_location: 'Stortorget, Karlskrona',
                fri_act2_location: 'Festvåningen på First Hotel Statt',
                fri_act2_time: '17:30 - 01:00',
                sat_act: 'BRÖLLOPSBAL',
                sat_act_time: '13:00',
                sat_act_location: 'Skärva Herrgård',
                sat_act_location2: 'Skärva 2, 371 91 Karlskrona',
                sun_act: 'Sommarbrunch',
                sun_act_time: '11:00',
                sun_act_location: 'Skärva Herrgård'

            },
            vision: {
              vision: "Kärleksfest",
              text1: "Vår bröllopsvision är en",
              text2: "där vi vill hinna umgås med alla er gäster, njuta av bröllopet och fira ordentligt! Vi har därför vi valt att gå förbi traditionen och dela upp vår bröllopsdag i två underbara dagar!",
              text3: "Vi vill att både vi och våra gäster ska få njuta av firandet av vår kärlek och Karlskronas miljö på ett lugnt och härligt sätt.",
              text4: "Därför har vi planerat in vår bröllopshelg på ett sätt som vi tror ni alla kommer uppskatta, med gott om möjlighet till både fest, egentid och svensk sommar.",
              cap1: "Skärgårdsbröllop med extra allt",
              cap2: "Kärleksfest i Sveriges finaste miljö"
            },
            rsvp: {
                header: "OSA",
                firstname: "Förnamn",
                lastname: "Efternamn",
                text: "Här kan du lämna ditt svar för bröllopshelgen. Är ni fler i hushållet som ska svara loggar ni in med era respektive koder och fyller i var sitt formulär.",
                edit: "Här kan du ändra dina uppgifter och svar för bröllopshelgen. Är ni fler i hushållet som ska svara loggar ni in med era respektive koder och fyller i var sitt formulär.",
                reply: "Ditt svar är: ",
                positive: "JAG KOMMER",
                negative: "JAG KOMMER INTE",
                save: "Spara",
                who_english: "Beskriv din relation till bruden och/eller brudgummen och något intressant om dig själv på ENGELSKA",
                who_swedish: "Beskriv din relation till bruden och/eller brudgummen och något instressant om dig själv på SVENSKA",
                allergies: "Allergier / andra matpreferenser",
                all_weekend: "JA! Jag önskar vara med hela helgen!",
                only_friday: "Endast fredag",
                only_saturday: "Endast lördag",
                attend_label: "Jag önskar vara med...",
                preferences_label: "Matpreferenser",
                alc_pref_label: "Alkoholpreferenser",
                alcohol: "Alkohol",
                non_alcohol: "Alkoholfritt",
                acc_label: "Boende",
                acc_all_weekend: "Jag önskar boende hela helgen",
                acc_fri_sun: "Jag önskar boende endast fredag till söndag",
                acc_people: "Jag önskar rum för så här många personer",
                acc_own: "Jag är från Karlskrona och behöver inte hjälp med boende",
                phone: "Mobilnummer",
                return: "OSA-formuläret är ännu inte klart, kom tillbaka den 25:e mars :)"
            },
            accommodation: {
                header: "Boende",
                intro: "Boendet arrangeras av brudparet och är inbokat på två platser. Boende är till ett förhandlat självkostnadspris med varierande priskategorier. Vår plan för helgen utgår från dessa platser och vi önskar därmed helst att alla tillresande gäster väljer de boenden vi har förbokat.",
                statt_info: "First Hotel Statt, ett sekelskifteshotell med beprövad frukostbuffé i Karlskrona centrum, brevid kyrkan och där vår Brudskål och Vigselfest är på fredagen. Här önskar vi att majoriteten bor.",
                skarva_info: "Skärva Herrgård, mitt i ett naturreservat vid havet tio minuter med bil från Karlskrona. Här finns endast några få rum, främst till Brudparet och brudföljet.",
                general_info1: "Boende anger ni i vårt formulär när ni OSA:r. Vi kommer sedan göra en indelning efter bästa förmåga för att tillgodose så många önskemål som möjligt på både plats och prisklass.",
                general_info2: "Vi behöver skicka in den fullständiga listan till hotellet",
                general_info3: "senast 45 dagar innan bröllopet.",
                statt_more: "Mer information om First Hotel Statt ",
                skarva_more: "Mer information om Skärva Herrgård ",
                here: "här",
                room: "Rum på"
            },
              guestlist: {
                  header: "Gästlista",
                  name: "Namn",
                  who: "Vem?",
                  table: "Bord",
                  room: "Rum"
              },
              nav: {
                information: 'INFORMATION',
                schedule: 'VÅR BRÖLLOPSHELG',
                rsvp: 'OSA',
                signed: 'Välkommen',
                accommodation: 'BOENDE',
                  guestlist: "Gästlista",
                  header: 'Meny',
                vision: 'VÅR BRÖLLOPSVISION'
              },
              info: {
                header: "Information",
                intro: "Här hoppas vi svara på de än så länge mest ställda frågorna. Är det något annat ni undrar över kan ni kontakta oss via mail på",
                swe_code: "0",
                karlskrona: "Hur kommer jag enklast till Karlskrona?",
                karlskrona_answer: "Det finns flera olika alternativ. Dels finns tåg tillgängliga för en relativt rimlig poäng, men det går även att flyga till Ronneby flygplats med både SAS och BRA Flyg. Är man stammis på ICA så kan man googla på \"ICA Bra Flyg\" för att hitta en länk där man kan hitta flygbiljetter mellan Bromma och Ronneby till en billig peng. Till och från Ronnebys flygplats går också en flygbuss som är anpassad till varje avgång med både SAS och BRA.",
                plus_one: "Får jag ta med en respektive?",
                plus_one_answer: "Tyvärr har vi behövt vara restriktiva med våra inbjudningar på grund av platsbrist och alla inbjudningar är därmed personliga.",
                children: "Är barn välkomna på bröllopet?",
                children_answer: "Vi har diskuterat med flera av våra gäster och syskon som är föräldrar och kommit fram till att ett barnfritt bröllop är något både vi och de flesta andra önskar, men ammande barn är självklart välkomna! Vi kommer lösa ett utrymme för föräldrar till och barnvakter för ammande barn att vara både på fredagen och på lördagen.",
                children_answer2: "Om det inte löser sig med barnvakt så är det helt lugnt att ta med barn! Vi kommer inte barnanpassa festerna, men vill inte vara så pass hårda på \"barnfritt\" att vi stoppar våra vänner från att komma. Hotellet har barnsängar de kan ställa in på rummen.",
                children_answer3: "Några av gästerna har småttingar som inte kan lämnas med barnvakt och några andra tar med sig en mor- eller farförälder som tar hand om barn under festerna. Vi kommer ha ett lite avskilt space för barnvakter. Hotellet kan också ställa in extrasängar i rummen, särskilt Superior eller Deluxe som är lite större än Standard.",
                spex: "Hur anmäler jag tal eller spex till festen/festerna?",
                spex_answer: "Till våra underbara Toastmasters, via email till",
                spex_email: "toast.felicialudvig@gmail.com",
                accommodation: "Hur ser boendesituationen ut?",
                accommodation_answer: "Vi har bokat boende till alla gäster till självkostnadspris uppdelat mellan Karlskrona Statt Hotell och Skärva Herrgård och vi behöver skicka in den fullständiga listan till hotellet senast 45 dagar innan bröllopet. Mer information på Boendefliken!",
                support: "Önskar ni stöttning med bröllopet?",
                support_answer: "Ja, gärna! Det finns massor med grejer som vi vill ha hjälp med, både stort och smått. Allt ifrån hjälp att ställa i ordning lokalerna, transport, osv. Hör gärna av dig till oss! :)",
                dresscode: "Vad är klädkoden?",
                dresscode_answer: "Mer info kommer så fort brudgummen bestämt vad han ska ha på sig!",
                dresscode_ladies: "Damer",
                dresscode_gents: "Herrar",
                dresscode_friday: "Fredag",
                dresscode_saturday: "Lördag",
                dresscode_ladies1: "Färgglad elegant klänning till mörk kostym",
                dresscode_ladies2: "Frack- & balklänning eller smoking- & aftonklänning",
                dresscode_gents1: "Mörk kostym, kavaj eller udda kavaj",
                dresscode_gents2: "Högtidsdräkt, frack, mässdräkt, eller smoking",
                dresscode_general: "Vi tycker att det är kul med bal och har därför valt dessa klädkoder efter det :) Man måste inte hyra en smoking för hela helgen om man inte vill det - viktigast av allt är att humöret är glatt och dansskorna på!",
                wedding_gift: "Bröllopspresent?",
                wedding_gift_answer: "Att ni alla tar er till Karlskrona för att fira med oss hela helgen är gåva nog, men om man vill kan man Swisha ett bidrag till vår bröllopsresa!",
                parking: "Hur ser parkeringsmöjligheterna ut?",
                parking_answer: "Det finns en parkeringsplats precis bredvid kyrkan för fredagsaktiviteterna. Dock så finns en risk att all parkering på torget är avstängd från och med midnatt lördag 27/7:e för att staden ska förbereda för Skärgårdsfesten. Ute på Skärva Herrgård ber vi er först släppa av eventuella passagerare uppe vid herrgården och sedan parkera vid ladorna nedanför.",
                what_do: "Vad finns det för kul grejer att göra i Karlskrona?",
                what_do_answer1: "Massor! Man kan ta skärgårdsbåtar ut för att se stan från sin bästa sida, flera golfbanor finns i närheten, och massor med väldigt bra restauranger. Det finns Marinmuseum och Grevagården/Blekingemuséet för de mer kultiverade. På torsdagar är det karaoke på Fox & Anchor, för de flesta andra.",
                what_do_answer2: "För de som vill tipsar vi också om att stanna kvar i Karlskrona för ",
                what_do_answer21: "som inträffar veckan direkt efter bröllopet!",
                what_do_answer22: "Det är en hel vecka fylld med saker som händer och massor med konserter, t.ex kommer Bolaget, Lars Winnerbäck och Veronica Maggio. Missa inte det!",
                restaurants_dinner: "Våra bästa restaurangtips för middag är:",
                restaurants_lunch: "För lunch tipsar vi om:",
                blomlofs: "Blomlöfs Fiskrökeri",
                michels: "Nattetid är Michel's det självklara valet! Testad och utvald av brudgummen själv."
              },
            days_one: '{{count}} dag',
            days_other: '{{count}} dagar',
            hours_one: '{{count}} timme',
            hours_other: '{{count}} timmar',
            minutes_one: '{{count}} minut',
            minutes_other: '{{count}} minuter',
            seconds_one: '{{count}} sekund',
            seconds_other: '{{count}} sekunder',
            celebrate: 'Vi har varit gifta i'
          }
        }
    }
  });

export const getLanguage = () => {
  return i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en';
};

export default i18n;